import React from "react";
import { arrowIcon } from "../Base/SVG";
import Work from "../Base/Work";

export default function ExploreWork() {
  return (
    <>
      <Work>
        <div className="work__inner-title">
          <h1>Explore Our Work</h1>
          <a href="#contact" className="arrow">
            <svg>{arrowIcon}</svg>
          </a>
        </div>
        <div className="work__inner-main">
          <div className="work__inner-row">
            <a href="#" className="workItem">
              <div className="workItem__image">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/work/explore-work-north.jpg"
                  }
                  alt=""
                />
              </div>
              <h3>
                NORTHWELL <br />
                HEALTH
              </h3>
            </a>
            <a href="#" className="workItem">
              <div className="workItem__image">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/work/explore-work-ceo.png"
                  }
                  alt=""
                />
              </div>
              <h3>
                CEO ACTION <br />
                FOR DIVERSITY & <br />
                INCLUSION
              </h3>
            </a>
            <a href="#" className="workItem">
              <div className="workItem__image">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/work/explore-work-aarp.png"
                  }
                  alt=""
                />
              </div>
              <h3>
                AARP <br />
                GLOBAL
              </h3>
            </a>
            <a href="#" className="workItem">
              <div className="workItem__image">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/work/explore-work-aws.jpg"
                  }
                  alt=""
                />
              </div>
              <h3>
                AWS <br />
                IMAGINE: NONPROFIT
              </h3>
            </a>
          </div>
          <div className="anchor" id="contact"></div>
          <div className="contact">
            <div className="contact__inner">
              <div className="contact__inner-content">
                <h2 className="big">Let's get started.</h2>
                <p className="big">Reach out and connect with our team.</p>
              </div>
              <a href="#" className="button primary">
                CONTACT US
              </a>
            </div>
          </div>
        </div>
      </Work>
    </>
  );
}
