import React from "react";
import { arrowIcon } from "../../Base/SVG";

export default function Intro() {
  return (
    <section className="intro">
      <div className="auto__container">
        <div className="intro__inner">
          <div className="intro__inner-title">
            <h1>
              See Your Success <br />
              in Action
            </h1>
            <p>
              Curate is your trusted partner in creating exceptional experiences
              that leave a lasting impression.
            </p>
            <a href="#contact" className="arrow">
              {arrowIcon}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
