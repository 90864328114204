import React from "react";

export default function Explore() {
  return (
    <section className="explore">
      <div className="auto__container">
        <div className="explore__inner">
          <a href="/solutions" className="exploreItem">
            <div className="exploreItem__image">
              <img
                src={
                  process.env.PUBLIC_URL + "/images/explore/home-solutions.jpg"
                }
                alt=""
              />
            </div>
            <h3>
              EXPLORE <br />
              SOLUTIONS
            </h3>
          </a>
          <a href="/work" className="exploreItem">
            <div className="exploreItem__image">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/explore/home-explore-our-work.png"
                }
                alt=""
              />
            </div>
            <h3>
              EXPLORE <br />
              OUR WORK
            </h3>
          </a>
          <a href="/aws" className="exploreItem">
            <div className="exploreItem__image">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/explore/home-case-studies.png"
                }
                alt=""
              />
            </div>
            <h3>
              CASE <br />
              STUDIES
            </h3>
          </a>
        </div>
      </div>
    </section>
  );
}
