import React from "react";
import { AnimatePresence } from "framer-motion";
import Modal from "../Solutions/Modal";

export default function Modals({ modal, setModal }) {
  return (
    <>
      <AnimatePresence>
        {modal === "modal-1" && (
          <Modal setModal={setModal}>
            <div className="modall__inner-content">
              <h2 className="sm">
                Project Management, Logistics and Production
              </h2>
              <p className="mid">
                The success of any Landmark Events production hinges on
                impeccable logistics and planning. Our experienced team
                meticulously plans and orchestrates every logistical aspect,
                from venue selection and layout optimization to transportation
                coordination and guest management. With a focus on precision and
                efficiency, we guarantee a seamless flow of activities, leaving
                clients and their guests free to indulge in being present for
                the event and focusing on driving the business objectives and
                leaving the tactical logistics to their behind-the-scenes
                Landmark Events Team.
              </p>
            </div>
            <div className="modall__inner-row">
              <div className="modallItem">
                <div className="modallItem__image">
                  <img src="./images/solutions/6.jpg" alt="" />
                </div>
              </div>
              <div className="modallItem">
                <div className="modallItem__image">
                  <img src="./images/solutions/7.jpg" alt="" />
                </div>
              </div>
            </div>
          </Modal>
        )}
        {modal === "modal-2" && (
          <Modal setModal={setModal}>
            <div className="modall__inner-content">
              <h2 className="sm">Tailored Program Design</h2>
              <p className="mid">
                At Landmark Events, our event programming design process is a
                blend of creativity, strategy, and precision. We understand that
                every event is unique and requires a tailored approach to create
                a harmonious flow of activities that resonate with your brand
                and objectives. Together we will work with you to accomplish:
              </p>
              <ul>
                <li>
                  <p className="mid">
                    <b>Thematic Excellence:</b>
                    We conceptualize innovative themes that resonate with your
                    event's purpose, creating a cohesive and immersive
                    experience for attendees.
                  </p>
                </li>
                <li>
                  <p className="mid">
                    <b>Agenda Construction:</b>
                    Our team excels in creating a meticulously structured agenda
                    that harmoniously blends informative sessions, engaging
                    interactive breakouts, captivating entertainment segments,
                    and strategically timed activities. Our agendas mirror the
                    dynamic pace at which people consume information in today's
                    fast-paced real-life swipe, ensuring that participants
                    remain fully engaged and continuously inspired throughout
                    the event.
                  </p>
                </li>
                <li>
                  <p className="mid">
                    <b>Technology Integration:</b>
                    We harness the power of cutting-edge technology to enhance
                    the attendee experience, whether through interactive apps,
                    live streaming, or virtual reality elements.
                  </p>
                </li>
                <li>
                  <p className="mid">
                    <b>Speaker Recruitment:</b>
                    Securing influential and captivating speakers is integral to
                    the success of any event. Our speaker recruitment
                    capabilities focus on identifying and engaging thought
                    leaders who can inspire, educate, and entertain your
                    audience.
                  </p>
                </li>
                <li>
                  <p className="mid">
                    <b>Extensive Network:</b>
                    Leveraging Landmark Ventures extensive industry connections,
                    we have access to a wide range of distinguished speakers
                    across various sectors, ensuring we find the perfect fit for
                    your event's theme and goals.
                  </p>
                </li>
                <li>
                  <p className="mid">
                    <b>Thorough Vetting:</b>
                    Our team meticulously evaluates potential speakers based on
                    their expertise, speaking ability, and alignment with your
                    event's vision, ensuring that only the most suitable
                    candidates are considered.
                  </p>
                </li>
                <li>
                  <p className="mid">
                    <b>Diverse Perspectives:</b>
                    We prioritize diversity and inclusivity in speaker
                    selection, ensuring a well-rounded lineup that represents
                    various backgrounds, viewpoints, and expertise areas.
                  </p>
                </li>
                <li>
                  <p className="mid">
                    <b>Engagement Strategy:</b>
                    We work closely with selected speakers to develop engaging
                    and impactful presentations that resonate with the audience,
                    ensuring a meaningful exchange of ideas and insights.
                  </p>
                </li>
              </ul>
            </div>
            <div className="modall__inner-row">
              <div className="modallItem">
                <div className="modallItem__image">
                  <img src="./images/solutions/8.jpg" alt="" />
                </div>
              </div>
              <div className="modallItem">
                <div className="modallItem__image">
                  <img src="./images/solutions/9.jpg" alt="" />
                </div>
              </div>
            </div>
          </Modal>
        )}
        {modal === "modal-3" && (
          <Modal setModal={setModal}>
            <div className="modall__inner-content">
              <h2 className="sm">Marketing & Audience Development</h2>
              <p className="mid">
                We recognize that exceptional events are not just about the
                experience– they're a reflection of a brand's identity and
                message. Curate partners with clients to develop comprehensive
                event branding and marketing strategies that resonate with
                target audiences. From carefully honing in on the profile of the
                perfect attendee, to engaging invitation campaigns to creating a
                personalized attendee journey with multiple touch points from
                the initial invitation to the final thank you.
              </p>
            </div>
            <div className="modall__inner-row">
              <div className="modallItem">
                <div className="modallItem__image">
                  <img src="./images/solutions/10.jpg" alt="" />
                </div>
              </div>
              <div className="modallItem">
                <div className="modallItem__image">
                  <img src="./images/explore/home-solutions.jpg" alt="" />
                </div>
              </div>
            </div>
          </Modal>
        )}
        {modal === "modal-4" && (
          <Modal setModal={setModal}>
            <div className="modall__inner-content">
              <h2 className="sm">Vendor Curation and Management</h2>
              <p className="mid">
                We pride ourselves on maintaining a vast network of reputable
                vendors and partners who share our dedication to excellence.
                Curate takes the burden off our clients by curating a handpicked
                selection of vendors, including top- tier venues, world-class
                entertainers, renowned photographers, and more. Our meticulous
                vendor management ensures seamless coordination, exceptional
                quality, and a flawless execution that exceeds expectations.
              </p>
            </div>
            <div className="modall__inner-row">
              <div className="modallItem">
                <div className="modallItem__image">
                  <img src="./images/solutions/11.jpg" alt="" />
                </div>
              </div>
              <div className="modallItem">
                <div className="modallItem__image">
                  <img src="./images/solutions/12.jpg" alt="" />
                </div>
              </div>
            </div>
          </Modal>
        )}
        {modal === "modal-5" && (
          <Modal setModal={setModal}>
            <div className="modall__inner-content">
              <h2 className="sm">
                Concierge Level Registration Experiences and Guest Services
              </h2>
              <p className="mid">
                We pride ourselves on maintaining a vast network of reputable
                vendors and partners who share our dedication to excellence.
                Curate takes the burden off our clients by curating a handpicked
                selection of vendors, including top- tier venues, world-class
                entertainers, renowned photographers, and more. Our meticulous
                vendor management ensures seamless coordination, exceptional
                quality, and a flawless execution that exceeds expectations.
              </p>
            </div>
            <div className="modall__inner-row">
              <div className="modallItem">
                <div className="modallItem__image">
                  <img src="./images/solutions/13.png" alt="" />
                </div>
              </div>
              <div className="modallItem">
                <div className="modallItem__image">
                  <img src="./images/solutions/14.jpg" alt="" />
                </div>
              </div>
            </div>
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
}
