import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "./Base/Header";
import Footer from "./Base/Footer";
import Home from "./Home/Home";
import Aws from "./Aws/Aws";
import Company from "./Company/Company";
import ExploreWork from "./ExploreWork/ExploreWork";
import Solutions from "./Solutions/Solutions";
import { Analytics } from "@vercel/analytics/react";

export default function App() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Header />
      <div className="main">
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="/aws" element={<Aws />} />
          <Route path="/company" element={<Company />} />
          <Route path="/work" element={<ExploreWork />} />
          <Route path="/solutions" element={<Solutions />} />
        </Routes>
      </div>
      <Analytics />
      <Footer />
    </>
  );
}
