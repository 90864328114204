import React, { useEffect, useState } from "react";
import {
  facebookIcon,
  flickrIcon,
  linkedIcon,
  twitterIcon,
  youtubeIcon,
} from "./SVG";
import { Link, useLocation } from "react-router-dom";
export default function Header() {
  const [menu, setMenu] = useState(false);
  const location = useLocation();

  const start = 50;
  const onScroll = React.useCallback(() => {
    if (window.scrollY > start) {
      document.getElementById("header").classList.add("sticky");
    } else {
      document.getElementById("header").classList.remove("sticky");
    }
  }, []);

  const closeFunc = (e) => {
    if (e.target === e.currentTarget) setMenu(false);
  };

  useEffect(() => {
    if (menu) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [menu]);

  useEffect(() => {
    setMenu(false);
    document.body.classList.remove("active");
  }, [location]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <header className="header" id="header">
      <div className="auto__container">
        <div className="header__inner">
          <Link to="/" className="header__inner-logo">
            <img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="logo" />
          </Link>
          <div className="header__inner-side">
            <ul className="header__inner-links">
              <li>
                <Link to="/company">COMPANY</Link>
              </li>
              <li>
                <Link to="/solutions">EXPLORE SOLUTIONS</Link>
              </li>
              <li>
                <Link to="/work">EXPLORE OUR WORK</Link>
              </li>
              <li>
                <Link to="#contact">CONNECT WITH CURATE</Link>
              </li>
            </ul>
            <div
              className={"burger " + (menu ? "active" : "")}
              onClick={() => setMenu(!menu)}
            >
              <span></span>
            </div>
          </div>
          <nav className={"nav " + (menu ? "active" : "")}>
            <div className="auto__container">
              <div className="nav__inner">
                <div className="nav__close" onClick={closeFunc}></div>
                <div className="nav__inner-row">
                  <div className="nav__inner-links">
                    <Link to="/company">Company</Link>
                    <Link to="/solutions">Explore Solutions</Link>
                    <Link to="/work">Explore Our Work</Link>
                    <Link to="/aws">Connect With Curate</Link>
                    <a href="#">Newsroom</a>
                    <a href="#">Careers</a>
                    <a href="#">Employee Resources</a>
                    <a href="#">Case Studies</a>
                  </div>
                  <div className="nav__inner-social">
                    <h4 className="big">Follow Us</h4>
                    <a href="#">
                      <span>{twitterIcon}</span>
                      Twitter
                    </a>
                    <a href="#">
                      <span>{facebookIcon}</span>
                      Facebook
                    </a>
                    <a href="#">
                      <span>{linkedIcon}</span>
                      Linkedin
                    </a>
                    <a href="#">
                      <span>{youtubeIcon}</span>
                      YouTube
                    </a>
                    <a href="#">
                      <span>{flickrIcon}</span>
                      Flickr
                    </a>
                  </div>
                  <form className="nav__inner-col">
                    <a href="#" className="button big">
                      Contact Us
                    </a>
                    <h4 className="big">Subscribe to our newsletter</h4>
                    <p className="sm">
                      Stay connected with Landmark and up to date on the latest
                      news, trends, and ideas in our sectors.
                    </p>
                    <div className="input">
                      <input type="email" placeholder="youremail@mail.com" />
                    </div>
                    <button type="submit" className="button sm">
                      Subscribe
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="nav__lines">
              <img src={process.env.PUBLIC_URL + "/images/nav.png"} alt="nav" />
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}
