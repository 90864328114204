import React from "react";

export default function Magic() {
  return (
    <section className="magic">
      <div className="auto__container">
        <div className="magic__inner">
          <div className="magic__inner-title">
            <h2>Experience, Magic.</h2>
            <p>
              Curate is a dynamic and specialized division operating within
              Landmark Ventures, designed to cater to the evolving and intricate
              needs of event management. Recognizing the increasing demand for
              comprehensive quality event solutions, Landmark Ventures
              established Curate in 2019, to focus exclusively on delivering
              exceptional white-labeled event planning services. With a strong
              commitment to excellence, creativity, and precision, Curate plays
              a pivotal role in enhancing Landmark Ventures' overall offerings.
            </p>
          </div>
          <div className="magic__inner-row">
            <div className="magicItem">
              <div className="magicItem__icon">
                <img
                  src={process.env.PUBLIC_URL + "/images/icons/design.png"}
                  alt=""
                />
              </div>
              <h4>
                PROGRAM <br />
                DESIGN
              </h4>
            </div>
            <div className="magicItem">
              <div className="magicItem__icon big">
                <img
                  src={process.env.PUBLIC_URL + "/images/icons/audience.png"}
                  alt=""
                />
              </div>
              <h4>
                AUDIENCE <br />
                DEVELOPMENT
              </h4>
            </div>
            <div className="magicItem">
              <div className="magicItem__icon">
                <img
                  src={process.env.PUBLIC_URL + "/images/icons/360.png"}
                  alt=""
                />
              </div>
              <h4>
                360 DEGREE <br />
                PRODUCTION
              </h4>
            </div>
            <div className="magicItem">
              <div className="magicItem__icon big">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/icons/experiential.png"
                  }
                  alt=""
                />
              </div>
              <h4>
                EXPERIENTIAL <br />
                BRANDING
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
