import React, { useEffect, useState } from "react";
import Work from "../Base/Work";

export default function Aws() {
  const [started, setStarted] = useState(false);
  // counter start

  const counter = (EL) => {
    setStarted(true);
    const duration = 1000;
    const start = parseInt(EL.textContent, 10);
    const end = parseInt(EL.dataset.counter, 10);
    if (start === end) return;

    const range = end - start;
    let curr = start;

    const timeStart = Date.now();
    const loop = () => {
      let elaps = Date.now() - timeStart;
      if (elaps > duration) elaps = duration;
      const frac = elaps / duration;
      const step = frac * range;
      curr = start + step;
      EL.textContent = Math.trunc(curr);
      if (elaps < duration) requestAnimationFrame(loop);
    };

    requestAnimationFrame(loop);
  };

  // counter end
  // + document.documentElement.clientHeight - 100
  function scrollCount() {
    try {
      if (
        window.scrollY >= document.getElementById("numbers").offsetTop &&
        !started
      ) {
        document.querySelectorAll("[data-counter]").forEach(counter);
      }
    } catch (error) {}
  }
  window.onscroll = function () {
    scrollCount();
  };

  useEffect(() => {
    scrollCount();
  }, []);
  return (
    <>
      <Work>
        <div className="work__inner-title">
          <h1>AWS IMAGINE: Nonprofit</h1>
        </div>
        <div className="work__inner-main">
          <div className="work__inner-text">
            <h2 className="big">What We Did</h2>
            <p id="numbers">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.".
            </p>
          </div>
          <div className="counter">
            <div className="counterItem">
              <h2 className="counterItem__number" data-counter="150">
                0
              </h2>
              <p className="big">SPEAKERS</p>
            </div>
            <div className="counterItem">
              <h2 className="counterItem__number" data-counter="500">
                0
              </h2>
              <p className="big">ATTENDEES</p>
            </div>
            <div className="counterItem">
              <h2 className="counterItem__number" data-counter="5">
                0
              </h2>
              <p className="big">ACTIVATIONS</p>
            </div>
          </div>
          <div className="work__inner-grid">
            <a href="#" className="workCard">
              <div className="workCard__image">
                <img
                  src={process.env.PUBLIC_URL + "/images/aws/1.png"}
                  alt=""
                />
              </div>
            </a>
            <a href="#" className="workCard">
              <div className="workCard__image">
                <img
                  src={process.env.PUBLIC_URL + "/images/aws/2.png"}
                  alt=""
                />
              </div>
            </a>
            <a href="#" className="workCard">
              <div className="workCard__image">
                <img
                  src={process.env.PUBLIC_URL + "/images/aws/3.png"}
                  alt=""
                />
              </div>
            </a>
            <a href="#" className="workCard">
              <div className="workCard__image">
                <img
                  src={process.env.PUBLIC_URL + "/images/aws/4.png"}
                  alt=""
                />
              </div>
            </a>
            <a href="#" className="workCard">
              <div className="workCard__image">
                <img
                  src={process.env.PUBLIC_URL + "/images/aws/5.png"}
                  alt=""
                />
              </div>
            </a>
            <a href="#" className="workCard">
              <div className="workCard__image">
                <img
                  src={process.env.PUBLIC_URL + "/images/aws/6.png"}
                  alt=""
                />
              </div>
            </a>
          </div>
          <div className="anchor" id="contact"></div>
          <div className="contact">
            <div className="contact__inner">
              <div className="contact__inner-content">
                <h2 className="big">Let's get started.</h2>
                <p className="big">Reach out and connect with our team.</p>
              </div>
              <a href="#" className="button primary">
                CONTACT US
              </a>
            </div>
          </div>
        </div>
      </Work>
    </>
  );
}
