import React, { useEffect, useState } from "react";
import Work from "../Base/Work";
import { arrowIcon } from "../Base/SVG";
import Modals from "../Base/Modals";

export default function Solutions() {
  const [modal, setModal] = useState(null);
  useEffect(() => {
    if (modal) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [modal]);

  return (
    <>
      <Work>
        <div className="work__inner-title">
          <h1>Explore Solutions</h1>
          <p>
            Landmark Events stands as the epitome of polished event planning,
            where creativity, precision, and exclusivity converge to create
            unforgettable experiences. With our portfolio of tailored solutions,
            we elevate events beyond expectations, setting the stage for
            unforgettable moments, and a legacy of excellence that lingers long
            after the event's conclusion. Embark on a journey of distinction
            with Landmark Events, where every detail matters and every moment is
            designed to be extraordinary.
          </p>
          <a href="#contact" className="arrow">
            {arrowIcon}
          </a>
        </div>
        <div className="work__inner-main">
          <div className="solutions">
            <div className="solutions__col">
              <div
                className="solutionsItem"
                onClick={() => setModal("modal-1")}
              >
                <div className="solutionsItem__image">
                  <img
                    src={process.env.PUBLIC_URL + "/images/solutions/1.jpg"}
                    alt=""
                  />
                </div>
                <div className="solutionsItem__content">
                  <h4>PROJECT MANAGEMENT, LOGISTICS AND PRODUCTION</h4>
                  <div className="solutionsItem__icon">{arrowIcon}</div>
                </div>
              </div>
              <div
                className="solutionsItem portrait "
                onClick={() => setModal("modal-2")}
              >
                <div className="solutionsItem__image">
                  <img
                    src={process.env.PUBLIC_URL + "/images/solutions/2.jpg"}
                    alt=""
                  />
                </div>
                <div className="solutionsItem__content">
                  <h4>MARKETING & AUDIENCE DEVELOPMENT</h4>
                  <div className="solutionsItem__icon">{arrowIcon}</div>
                </div>
              </div>
            </div>
            <div className="solutions__row">
              <div
                className="solutionsItem album "
                onClick={() => setModal("modal-3")}
              >
                <div className="solutionsItem__image">
                  <img
                    src={process.env.PUBLIC_URL + "/images/solutions/3.jpg"}
                    alt=""
                  />
                </div>
                <div className="solutionsItem__content">
                  <h4>
                    TAILORED <br />
                    PROGRAM DESIGN
                  </h4>
                  <div className="solutionsItem__icon">{arrowIcon}</div>
                </div>
              </div>
              <div
                className="solutionsItem "
                onClick={() => setModal("modal-4")}
              >
                <div className="solutionsItem__image">
                  <img
                    src={process.env.PUBLIC_URL + "/images/solutions/4.jpg"}
                    alt=""
                  />
                </div>
                <div className="solutionsItem__content">
                  <h4>VENDOR CURATION AND MANAGEMENT</h4>
                  <div className="solutionsItem__icon">{arrowIcon}</div>
                </div>
              </div>
              <div
                className="solutionsItem"
                onClick={() => setModal("modal-5")}
              >
                <div className="solutionsItem__image">
                  <img
                    src={process.env.PUBLIC_URL + "/images/solutions/5.jpg"}
                    alt=""
                  />
                </div>
                <div className="solutionsItem__content">
                  <h4>
                    CONCIERGE LEVEL REGISTRATION EXPERIENCES AND GUEST SERVICES
                  </h4>
                  <div className="solutionsItem__icon">{arrowIcon}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="anchor" id="contact"></div>
          <div className="contact">
            <div className="contact__inner">
              <div className="contact__inner-content">
                <h2 className="big">Let's get started.</h2>
                <p className="big">Reach out and connect with our team.</p>
              </div>
              <a href="#" className="button primary">
                CONTACT US
              </a>
            </div>
          </div>
        </div>
      </Work>
      <Modals modal={modal} setModal={setModal} />
    </>
  );
}
