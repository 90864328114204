import React, { useEffect, useState } from "react";
import Intro from "./sections/Intro";
import Magic from "./sections/Magic";
import Explore from "./sections/Explore";

export default function Home() {
  return (
    <>
      <Intro/>
      <Explore/>
      <Magic />
    </>
  );
}
