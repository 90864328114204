import React from "react";
import Work from "../Base/Work";
import { arrowIcon } from "../Base/SVG";

export default function Company() {
  return (
    <>
      <Work>
        <div className="work__inner-title">
          <h1>Company</h1>
          <p>
            Landmark Events is a dynamic event planning solution operating under
            the umbrella of Landmark Ventures, a prominent Business Development
            and Investment Banking Firm headquartered in the vibrant city of New
            York. Our mission at Landmark Events is to cater to the
            ever-evolving and intricate needs of business event management.
            Recognizing the demand for comprehensive and top-tier event
            solutions, coupled with our extensive subject matter expertise in a
            diverse array of topics, Landmark Ventures launched Landmark Events
            in 2019. This dedicated business solution focuses exclusively on
            providing exceptional white-labeled event planning services. With an
            unwavering commitment to excellence, creativity, and precision,
            Landmark Events plays a critical role in enhancing Landmark
            Ventures' already robust suite of business offerings.
          </p>
          <a href="#contact" className="arrow">
            {arrowIcon}
          </a>
        </div>
        <div className="work__inner-main">
          <div className="work__inner-text">
            <h2 className="big">Our Mission</h2>
            <p>
              At Landmark Events, we are dedicated to crafting seamless and
              unforgettable events and experiences that not only captivate and
              engage, but also leave an indelible mark. Our mission is to
              eliminate the challenges associated with event planning by
              offering tailored, end-to-end event solutions. Our expertise spans
              a wide spectrum of events, encompassing corporate conferences and
              summits, executive off-sites, digital thought leadership series,
              high-touch VIP experiences, and strategic event consulting. By
              specializing in the fusion of event planning and thought
              leadership, we bring a heightened level of focus and proficiency
              to ensure the flawless execution of every detail.
            </p>
          </div>
          <div className="workRow">
            <div className="workRow__content">
              <h2>Expertise and Specialization</h2>
              <p>
                Curate consists of a dedicated full-time team of experienced
                professionals dispersed across the globe who possess an
                unparalleled understanding of event planning intricacies. Our
                expertise covers a wide spectrum of events, including corporate
                conferences & summits, executive off-sites, client engagement
                events, digital thought leadership series, gala dinners,
                high-touch VIP experiences and strategic event consulting. By
                specializing in event planning, we bring a heightened level of
                focus and proficiency to ensure every detail is flawlessly
                executed.
              </p>
            </div>
            <div className="workRow__image-wrapper">
              <div className="workRow__image">
                <img
                  src={process.env.PUBLIC_URL + "/images/company/1.png"}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="workRow">
            <div className="workRow__image-wrapper">
              <div className="workRow__image">
                <img
                  src={process.env.PUBLIC_URL + "/images/company/2.png"}
                  alt=""
                />
              </div>
            </div>
            <div className="workRow__content">
              <h2>Collaborative Approach</h2>
              <p>
                We firmly believe that successful event planning is a
                collaborative endeavor. Landmark Events, leverages the
                resources, infrastructure, and networks of the Firm to provide
                in-depth expertise to our comprehensive event solutions. Our
                collaboration with other divisions within Landmark Ventures
                allows us to seamlessly integrate our expertise in event
                planning with industry leading Thought Leadership and reach into
                some of the most highly coveted networks within the marketing,
                consumer & digital engagement, cybersecurity, enterprise IT,
                industrial tech, investment banking, and business development
                sectors, resulting in a holistic and impactful experience for
                our clients where they benefit from the breadth and depth of our
                full reach.
              </p>
            </div>
          </div>
          <div className="workRow">
            <div className="workRow__content">
              <h2>Innovation and Creativity</h2>
              <p>
                For the Landmark Events Team innovation and creativity are at
                the heart of everything we do. Our team thrives on pushing the
                boundaries of traditional event planning, continuously seeking
                new ways to create unique and memorable experiences. By being a
                part of Landmark Ventures, we have access to cutting-edge
                insights into the most relevant trends, stories, and topics
                shaping our world today which allows us to design authentic
                events that resonate with attendees.
              </p>
            </div>
            <div className="workRow__image-wrapper">
              <div className="workRow__image">
                <img
                  src={process.env.PUBLIC_URL + "/images/company/3.png"}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="workRow">
            <div className="workRow__image-wrapper">
              <div className="workRow__image">
                <img
                  src={process.env.PUBLIC_URL + "/images/company/4.png"}
                  alt=""
                />
              </div>
            </div>
            <div className="workRow__content">
              <h2>Client-Centric Approach</h2>
              <p>
                We prioritize our clients' needs and objectives above all else.
                Curate invests time in understanding our clients' visions,
                goals, and target audiences to tailor events that align with
                their brand identity and messaging. With a relentless commitment
                to delivering value and exceeding expectations, we ensure that
                each event becomes a powerful platform for our clients to
                achieve most audacious business goals and desired outcomes.
              </p>
            </div>
          </div>
          <div className="workList">
            <div className="workList__item">
              <div className="workList__item-number">1</div>
              <h5>
                Unlock New <br />
                Opportunities and Forge Valuable Partnerships
              </h5>
              <p className="sm">
                Experience the expertise of the Landmark Events Team, dedicated
                to creating immersive experiences that not only attract valuable
                leads but also foster strategic partnerships. Expand your
                network and seize new business opportunities with our
                specialized approach.
              </p>
            </div>
            <div className="workList__item">
              <div className="workList__item-number">2</div>
              <h5>
                Elevate Your <br />
                Thought Leadership
              </h5>
              <p className="sm">
                Collaborating with Landmark Events is your gateway to elevating
                your brand's thought leadership. Our meticulously crafted
                Summits, Webinars, Salons, and exclusive VIP Dinner experiences
                position your brand as an industry authority.
              </p>
            </div>
            <div className="workList__item">
              <div className="workList__item-number">3</div>
              <h5>
                Amplify Your <br />
                Brand Exposure
              </h5>
              <p className="sm">
                At Landmark Events, we're masters of Event Marketing and getting
                the right people in the right rooms. Our seasoned team ensures
                your brand has widespread exposure, boosting awareness and
                visibility within your target audience. Your message will reach
                your father and resonate deeper.
              </p>
            </div>
            <div className="workList__item">
              <div className="workList__item-number">4</div>
              <h5>
                Tailored <br />
                Event Solutions
              </h5>
              <p className="sm">
                Leave the logistics, production, technology, and creative
                designs to us. Landmark Events takes care of every aspect of
                event execution, guaranteeing a seamless and unforgettable
                experience that enhances your brand's image.
              </p>
            </div>
            <div className="workList__item">
              <div className="workList__item-number">5</div>
              <h5>Comprehensive Event Management</h5>
              <p className="sm">
                Landmark Events handles every aspect of event execution, from
                sourcing, contracting and logistics and production to technology
                and creative design, ensuring a seamless and memorable and brand
                enhancing experience for attendees.
              </p>
            </div>
            <div className="workList__item">
              <div className="workList__item-number">6</div>
              <h5>
                Professionalism <br />
                and Poise
              </h5>
              <p className="sm">
                Landmark Ventures Senior Executives are seasoned professionals
                who excel in high- pressure situations. Their presence as event
                emcees ensures a polished and well-executed event. From opening
                introductions to panel discussions, our executives are trained
                to share innovative ideas and humbly facilitate event
                transitions that position your brand as a confident industry
                leader.
              </p>
            </div>
            <div className="workList__item">
              <div className="workList__item-number">7</div>
              <h5>
                Tailored Event <br /> Solutions
              </h5>
              <p className="sm">
                Curate boasts a remarkable track record, consistently delivering
                exceptional events for Fortune 500 corporations and the most
                highly respected privately held companies. Each event we craft
                serves as a potent marketing tool, driving engagement and
                fostering positive business
              </p>
            </div>
          </div>
          <div className="workRow">
            <div className="workRow__image-wrapper">
              <div className="workRow__image">
                <img
                  src={process.env.PUBLIC_URL + "/images/company/4.png"}
                  alt=""
                />
              </div>
            </div>
            <div className="workRow__content">
              <h2>Client-Centric Approach</h2>
              <p>
                We prioritize our clients' needs and objectives above all else.
                Curate invests time in understanding our clients' visions,
                goals, and target audiences to tailor events that align with
                their brand identity and messaging. With a relentless commitment
                to delivering value and exceeding expectations, we ensure that
                each event becomes a powerful platform for our clients to
                achieve most audacious business goals and desired outcomes.
              </p>
            </div>
          </div>
          <div className="workList">
            <div className="workList__item">
              <div className="workList__item-number">1</div>
              <h5>
                Unlock New <br />
                Opportunities and Forge Valuable Partnerships
              </h5>
              <p className="sm">
                Experience the expertise of the Landmark Events Team, dedicated
                to creating immersive experiences that not only attract valuable
                leads but also foster strategic partnerships. Expand your
                network and seize new business opportunities with our
                specialized approach.
              </p>
            </div>
            <div className="workList__item">
              <div className="workList__item-number">2</div>
              <h5>
                Elevate Your <br />
                Thought Leadership
              </h5>
              <p className="sm">
                Collaborating with Landmark Events is your gateway to elevating
                your brand's thought leadership. Our meticulously crafted
                Summits, Webinars, Salons, and exclusive VIP Dinner experiences
                position your brand as an industry authority.
              </p>
            </div>
            <div className="workList__item">
              <div className="workList__item-number">3</div>
              <h5>
                Amplify Your <br />
                Brand Exposure
              </h5>
              <p className="sm">
                At Landmark Events, we're masters of Event Marketing and getting
                the right people in the right rooms. Our seasoned team ensures
                your brand has widespread exposure, boosting awareness and
                visibility within your target audience. Your message will reach
                your father and resonate deeper.
              </p>
            </div>
            <div className="workList__item">
              <div className="workList__item-number">4</div>
              <h5>
                Tailored <br />
                Event Solutions
              </h5>
              <p className="sm">
                Leave the logistics, production, technology, and creative
                designs to us. Landmark Events takes care of every aspect of
                event execution, guaranteeing a seamless and unforgettable
                experience that enhances your brand's image.
              </p>
            </div>
            <div className="workList__item">
              <div className="workList__item-number">5</div>
              <h5>Comprehensive Event Management</h5>
              <p className="sm">
                Landmark Events handles every aspect of event execution, from
                sourcing, contracting and logistics and production to technology
                and creative design, ensuring a seamless and memorable and brand
                enhancing experience for attendees.
              </p>
            </div>
            <div className="workList__item">
              <div className="workList__item-number">6</div>
              <h5>
                Professionalism <br />
                and Poise
              </h5>
              <p className="sm">
                Landmark Ventures Senior Executives are seasoned professionals
                who excel in high- pressure situations. Their presence as event
                emcees ensures a polished and well-executed event. From opening
                introductions to panel discussions, our executives are trained
                to share innovative ideas and humbly facilitate event
                transitions that position your brand as a confident industry
                leader.
              </p>
            </div>
            <div className="workList__item">
              <div className="workList__item-number">7</div>
              <h5>
                Tailored Event <br /> Solutions
              </h5>
              <p className="sm">
                Curate boasts a remarkable track record, consistently delivering
                exceptional events for Fortune 500 corporations and the most
                highly respected privately held companies. Each event we craft
                serves as a potent marketing tool, driving engagement and
                fostering positive business
              </p>
            </div>
          </div>
          <div className="anchor" id="contact"></div>
          <div className="contact">
            <div className="contact__inner">
              <div className="contact__inner-content">
                <h2 className="big">Let's get started.</h2>
                <p className="big">Reach out and connect with our team.</p>
              </div>
              <a href="#" className="button primary">
                CONTACT US
              </a>
            </div>
          </div>
        </div>
      </Work>
    </>
  );
}
