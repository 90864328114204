import React from "react";
import {
  facebookIcon,
  flickrIcon,
  linkedIcon,
  twitterIcon,
  youtubeIcon,
} from "./SVG";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="auto__container">
        <div className="footer__inner">
          <div className="footer__inner-top">
            <div className="footer__inner-logo">
              <img
                src={process.env.PUBLIC_URL + "/images/footer-logo.png"}
                alt="logo"
              />
            </div>
            <h5>At Landmark Ventures we build exceptional relationships</h5>
          </div>
          <div className="footer__inner-row">
            <div className="footer__inner-col">
              <a href="#">About</a>
              <a href="#">People</a>
              <a href="#">Services</a>
              <a href="#">Expertise</a>
              <a href="#">Portfolio</a>
              <a href="#">Newsroom</a>
              <a href="#">Careers</a>
              <a href="#">Case Studies</a>
            </div>
            <div className="footer__inner-col">
              <a href="#">Newsroom</a>
              <a href="#">Careers</a>
              <a href="#">Contact</a>
              <a href="#">Case Studies</a>
            </div>
            <div className="footer__inner-main">
              <h6>Subscribe to our newsletter</h6>
              <p className="sm">
                Stay connected with Landmark and up to date on the latest news,
                trends, and ideas in our sectors.
              </p>
              <div className="input">
                <input type="email" placeholder="youremail@mail.com" />
              </div>
              <button type="button" className="button">
                Subscribe
              </button>
            </div>
            <div className="footer__inner-col social">
              <a href="#">
                <span>{twitterIcon}</span>
                Twitter
              </a>
              <a href="#">
                <span>{facebookIcon}</span>
                Facebook
              </a>
              <a href="#">
                <span>{linkedIcon}</span>
                Linkedin
              </a>
              <a href="#">
                <span>{youtubeIcon}</span>
                YouTube
              </a>
              <a href="#">
                <span>{flickrIcon}</span>
                Flickr
              </a>
            </div>
          </div>
          <div className="footer__inner-copy">
            <div className="footer__inner-copy-links">
              <a href="#">Terms of use</a>
              <hr />
              <a href="#">Privacy Policy</a>
            </div>
            <p className="sm">© 2022 Landmark Ventures. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
