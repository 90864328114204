import React from "react";

export default function Work({ children }) {
  return (
    <section className="work">
      <div className="auto__container">
        <div className="work__inner">{children}</div>
      </div>
    </section>
  );
}
